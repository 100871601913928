<script setup>
import { state } from '../store/store.js';
</script>

<script>
    function round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    export default {
        name: 'Admin',
        components: {},
        data() {
            return {
                pageState: 'landing',
                dirty: false,
                //Approvals
                approvals: [],
                approvalsOrig: [],
                approvalTotalPages: 1,
                approvalCurrentPage: 1,
                approvalTableState: {
                    rows: [],
                    rowsPrePagination: [],
                    selectedRows: []
                },
                approvalFilters: {
                    status: { value: '', custom: this.approvalDecisionFilter },
                    user: { value: '', keys: ['user'] },
                    type: { value: '', keys: ['lead'], exact: true },
                    county: { value: '', keys: ['county'] },
                    state: { value: '', keys: ['state'] }
                },
                approvalRowsInVewSelected: false,
                approvalAllRowsSelected: false,
                approvalBulkEdit: false,

                //SLOs
                slos: [],
                sloTotalPages: 1,
                sloCurrentPage: 1,
                sloTableState: {
                    rows: [],
                    rowsPrePagination: [],
                    selectedRows: []
                },
                sloFilters: {
                    user: { value: '', keys: ['user'] },
                    type: { value: '', keys: ['leadtype'], exact: true },
                    county: { value: '', keys: ['county'] },
                    state: { value: '', keys: ['state'] }
                },
                sloRowsInVewSelected: false,
                sloAllRowsSelected: false,
                sloBulkEdit: false,
                sloBulkEditing: false,
                sloBulkCap: -1,

                //Waitlists
                waitlists: [],
                waitlistTotalPages: 1,
                waitlistCurrentPage: 1,
                waitlistTableState: {
                    rows: [],
                    rowsPrePagination: [],
                    selectedRows: []
                },
                waitlistFilters: {
                    user: { value: '', keys: ['user'] },
                    type: { value: '', keys: ['leadtype'], exact: true },
                    county: { value: '', keys: ['county'] },
                    state: { value: '', keys: ['state'] }
                },
                waitlistRowsInVewSelected: false,
                waitlistAllRowsSelected: false,
                waitlistBulkEdit: false,
                waitlistBulkEditing: false,
                waitlistBulkCap: -1,

                //Invoices
                invoices: [],
                invoiceTotalPages: 1,
                invoiceCurrentPage: 1,
                invoiceTableState: {
                    rows: [],
                    rowsPrePagination: [],
                    selectedRows: []
                },
                invoiceFilters: {
                    agent: { value: '', keys: ['agent'] },
                    status: { value: '', keys: ['status'] },
                    invoices: { value: '', keys: ['invoices'] },
                    sloswaitlists: { value: '', keys: ['sloswaitlists'] }
                },
                //Performance
                allAgents: [{username: 'Loading...', rid: 0}],
                activeAgent: 0,
                viewAsUser: 0,
                leadTypes: ['VF','VF0','V1','V2','V3','VB1', 'VB2', 'VB3'],
                ratioActiveType: 'VF',
                aplActiveType: 'VF',
                closingRatio: {
                    ratio: 0,
                    sold: 0,
                    unsold: 0,
                },
                apl: {
                    average: 0,
                    total: 0,
                    leadCount: 0,
                }
            }
        },
        async mounted() {
            if (state.user.role !== 'admin') {
                this.$router.push({ name: 'MyAccount' });
            }
            try {
                state.currentView = 'admin';
                await this.prepInvoices();
                await this.prepApprovals();
                await this.prepSLOs();
                await this.prepWaitlists();

                let agents = await state.api.call("getUsers");
                this.allAgents = agents.users;
                if (this.allAgents.length) {
                    this.activeAgent = this.allAgents[0]['rid'];
                    this.viewAsUser = this.allAgents[0]['rid'];
                }

                await this.setClosingRatio();
                await this.setAPL();
            } catch (error) {
                console.error(error);
            }
        },
        watch: {
            dirty() {
                //If comment fields have unsaved edits then warn user before closing window
                if (this.dirty) {
                    window.addEventListener('beforeunload', this.beforeUnloadHandler);
                }else {
                    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
                }
            },
            async ratioActiveType() {
                await this.setClosingRatio();
            },
            async aplActiveType() {
                await this.setAPL();
            },
            async activeAgent() {
                await this.setClosingRatio();
                await this.setAPL();
            }
        },
        methods: {
            toggleRowsInView(table) {
                let rivVar = null,
                    arVar = null,
                    tsVar = null,
                    beVar = null;
                if (table === 'activeWaitlists') {
                    rivVar = 'waitlistRowsInVewSelected';
                    arVar = 'waitlistAllRowsSelected';
                    tsVar = 'waitlistTableState';
                    beVar = 'waitlistBulkEdit';
                }else if (table === 'activeSLOs') {
                    rivVar = 'sloRowsInVewSelected';
                    arVar = 'sloAllRowsSelected';
                    tsVar = 'sloTableState';
                    beVar = 'sloBulkEdit';
                }else if (table === 'activeApprovals') {
                    rivVar = 'approvalRowsInVewSelected';
                    arVar = 'approvalAllRowsSelected';
                    tsVar = 'approvalTableState';
                    beVar = 'approvalBulkEdit';
                }else if (table === 'activeInvoices') {
                    rivVar = 'invoiceRowsInVewSelected';
                    arVar = 'invoiceAllRowsSelected';
                    tsVar = 'invoiceTableState';
                    beVar = 'invoiceBulkEdit';
                }
                if (this[rivVar] === true) {
                    this.$refs[table].deselectAll();
                    this[rivVar] = false;
                    this[arVar] = false;
                    this[beVar] = false;
                }else {
                    if (table === 'activeApprovals') {
                        this.$refs[table].selectRows(this[tsVar].rows.filter(r => r.decision === ''));
                    }else if (table === 'activeInvoices') {
                        this.$refs[table].selectRows(this[tsVar].rows.filter(r => r.status !== 'Paid'));
                    }else {
                        this.$refs[table].selectRows(this[tsVar].rows);
                    }
                    this[rivVar] = true;
                    this[beVar] = true;

                    if (this[tsVar].rowsPrePagination.length === this[tsVar].selectedRows.length) {
                        this[arVar] = true;
                    }else {
                        this[arVar] = false;
                    }
                }
            },
            selectAllRows(table) {
                let arVar = null,
                    beVar = null;
                if (table === 'activeWaitlists') {
                    arVar = 'waitlistAllRowsSelected';
                    beVar = 'waitlistBulkEdit';
                }else if (table === 'activeSLOs') {
                    arVar = 'sloAllRowsSelected';
                    beVar = 'sloBulkEdit';
                }else if (table === 'activeApprovals') {
                    arVar = 'approvalAllRowsSelected';
                    beVar = 'approvalBulkEdit';
                }else if (table === 'activeInvoices') {
                    arVar = 'invoiceAllRowsSelected';
                    beVar = 'invoiceBulkEdit';
                }
                this.$refs[table].selectAll();
                this[arVar] = true;
                this[beVar] = true;
            },
            tableRowClicked(table) {
                let rivVar = null,
                    arVar = null,
                    tsVar = null,
                    beVar = null,
                    showBulk = true;

                if (table === 'activeWaitlists') {
                    rivVar = 'waitlistRowsInVewSelected';
                    arVar = 'waitlistAllRowsSelected';
                    tsVar = 'waitlistTableState';
                    beVar = 'waitlistBulkEdit';
                }else if (table === 'activeSLOs') {
                    rivVar = 'sloRowsInVewSelected';
                    arVar = 'sloAllRowsSelected';
                    tsVar = 'sloTableState';
                    beVar = 'sloBulkEdit';
                }else if (table === 'activeApprovals') {
                    rivVar = 'approvalRowsInVewSelected';
                    arVar = 'approvalAllRowsSelected';
                    tsVar = 'approvalTableState';
                    beVar = 'approvalBulkEdit';
                }else if (table === 'activeInvoices') {
                    rivVar = 'invoiceRowsInVewSelected';
                    arVar = 'invoiceAllRowsSelected';
                    tsVar = 'invoiceTableState';
                    beVar = 'invoiceBulkEdit';
                }
                setTimeout(() => {
                    //Set All Checked
                    if (table === 'activeApprovals' && this[tsVar].rows.filter(r => r.decision === '').length === this[tsVar].selectedRows.length) {
                        this[arVar] = true;
                        this[rivVar] = true;
                    }else if (table === 'activeInvoices' && this[tsVar].rows.filter(r => r.status !== 'Paid').length === this[tsVar].selectedRows.length) {
                        this[arVar] = true;
                        this[rivVar] = true;
                    }else if (this[tsVar].rows.length === this[tsVar].selectedRows.length) {
                        this[arVar] = true;
                        this[rivVar] = true;
                    } else {
                        this[arVar] = false;
                        this[rivVar] = false;
                    }
                    //Show Batch Options
                    if (this[tsVar].selectedRows.length > 1 && showBulk) {
                        this[beVar] = true;
                    }else {
                        this[beVar] = false;
                    }
                }, 100);
            },
            async prepApprovals() {
                let approvalsReq = await state.api.call("getApprovals");
                this.approvalsOrig = approvalsReq.approvals.map(a => {
                    return {
                        rid: a.rid,
                        comments: a.comments
                    };
                });
                this.approvals = approvalsReq.approvals.map(a => {
                    a.date = new Date(a.date);
                    return a;
                });
            },
            async prepSLOs() {
                let slosReq = await state.api.call("getAdminSLOs");
                this.slos = slosReq.slos;
            },
            async prepWaitlists() {
                let waitlistsReq = await state.api.call("getAdminWaitlists");
                this.waitlists = waitlistsReq.waitlists.map(a => {
                    a.date = new Date(a.date);
                    return a;
                });
            },
            async prepInvoices() {
                let invoicesReq = await state.api.call("getAdminInvoices");
                // console.log(invoicesReq);
                this.invoices = invoicesReq.invoices.map(i => {
                    i.invoices = i.invoices.join(', ');
                    i.sloswaitlists = i.sloswaitlists.join(' | ');
                    let totalLeads = '';
                    for (let leadType in i.totalLeads) {
                        let leadTotal = i.totalLeads[leadType];
                        if (leadTotal > 0) {
                            totalLeads += `${leadType}: ${leadTotal} | `;
                        }
                    }
                    i.totalLeads = totalLeads.slice(0, -3);
                    return i;
                });
            },
            beforeUnloadHandler(event) {
                event.preventDefault();
                event.returnValue = true;
            },
            back() {
                this.pageState = 'landing';
            },
            sanitizeInput(str) {
                const entityMap = {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#39;',
                    '/': '&#x2F;',
                    '`': '&#x60;',
                    '=': '&#x3D;'
                };
                return String(str).replace(/[&<>"'`=/]/g, s => entityMap[s]);
            },
            deSanitizeInput(str) {
                const entityMap = {
                    '&amp;'     : '&',
                    '&lt;'      : '<',
                    '&gt;'      : '>',
                    '&quot;'    : '"',
                    '&#39;'     : "'",
                    '&#x2F;'    : '/',
                    '&#x60;'    : '`',
                    '&#x3D;'    : '='
                };
                for (let ent in entityMap) {
                    str = String(str).replaceAll(ent, entityMap[ent]);
                }
                return str;
            },
            filterToggle(event) {
                event.stopPropagation();
                event.target.closest('th').classList.toggle('-filter');
            },
            invoiceTotal(row) {
                return parseFloat(row.total.replace('$','').replace(',',''));
            },
            approvalDecision(row) {
                if (row.decision && row.decision !== '') {
                    return "Approved";
                }else if (row.decision === '') {
                    return "Pending";
                }else {
                    return "Denied";
                }
            },
            dateSort(row) {
                return row.date.toISOString();
            },
            approvalDecisionFilter(value, row) {
                let decision = row.decision,
                    val = value.toLowerCase().trim();
                // console.log(val);
                if (decision && decision !== '' && 'approved'.includes(val)) { //approved
                    return true;
                }else if (decision === '' && 'pending'.includes(val)) { //Pending
                    return true;
                }else if ('denied'.includes(val) && typeof decision === "boolean" && !decision) { //Denied
                    return true;
                }
                return false;
            },
            editingComments(event, approval) {
                this.dirty = true;
                approval.comments = event.target.value.trim();
            },
            //Save comment edits to approval
            async saveComments(approval) {
                let process = false,
                    message = 'Comment posted successfully.';
                if (approval.comments) {
                    process = true;
                }else {
                    //If comment used to have a value that is now removed
                    let origA = this.approvalsOrig.filter(a => a.rid === approval.rid);
                    if (origA[0].comments) {
                        process = true;
                        message = 'Comment removed successfully.';
                    }
                }
                if (process) {
                    try {
                        let res = await state.api.call("updateApprovalComments", {
                            reqID: approval.rid,
                            comments: this.sanitizeInput(approval.comments)
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: message
                            });
                            // console.log(res.result);
                            this.dirty = false;
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            resetApprovalsBulk() {
                this.approvalRowsInVewSelected = false;
                this.approvalAllRowsSelected = false;
                this.approvalBulkEdit = false;
                this.$refs['activeApprovals'].deselectAll();
            },
            async bulkSetApprovalStatus(status) {
                try {
                    let res = await state.api.call("updateApprovalStatus", {
                        reqIDs: this.approvalTableState.selectedRows.map(el => el.rid),
                        status: status
                    });
                    if (res.result && res.result[0] && res.result[0].success) {
                        state.flash({
                            type: 'success',
                            message: 'Approval requests status’ have been saved successfully.'
                        });
                        
                        await this.prepInvoices();
                        await this.prepApprovals();
                        await this.prepSLOs();
                        await this.prepWaitlists();
                        this.resetApprovalsBulk();
                    } else {
                        throw new Error('Something went wrong. Please refresh and try again.');
                    }
                }catch(error) {
                    console.error(error);
                    state.flash({
                        type: 'error',
                        message: error.message
                    });
                }
            },
            async setApprovalStatus(approval,status) {
                try {
                    let res = await state.api.call("updateApprovalStatus", {
                        reqIDs: approval.rid,
                        status: status
                    });
                    if (res.result && res.result[0] && res.result[0].success) {
                        state.flash({
                            type: 'success',
                            message: 'Approval request status has been saved successfully.'
                        });
                        approval.decision = res.result[0].decision;
                        
                        await this.prepInvoices();
                        await this.prepApprovals();
                        await this.prepSLOs();
                        await this.prepWaitlists();
                    } else {
                        throw new Error('Something went wrong. Please refresh and try again.');
                    }
                } catch (error) {
                    console.error(error);
                    state.flash({
                        type: 'error',
                        message: error.message
                    });
                }
            },
            cancelBulkSLOsEdit() {
                this.dirty = false;
                this.sloBulkEditing = false;
            },
            cancelSLOEdit(slo) {
                slo.payment = slo.savedPayment;
                this.dirty = false;
                slo.editing = false;
            },
            editBulkSLOs() {
                this.dirty = true;
                this.sloBulkEditing = true;
            },
            editSLO(slo) {
                this.dirty = true;
                slo.editing = true;
            },
            resetSLOsBulk() {
                this.sloRowsInVewSelected = false;
                this.sloAllRowsSelected = false;
                this.sloBulkEdit = false;
                this.sloBulkEditing = false;
                this.$refs['activeSLOs'].deselectAll();
                this.sloBulkCap = -1;
            },
            async saveSLOs() {
                if (this.sloTableState.selectedRows.length) {
                    try {
                        let res = await state.api.call("updateSLOCap", {
                            sloIDs: this.sloTableState.selectedRows.map(el => el.rid),
                            cap: this.sloBulkCap
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'SLOs’ cap saved successfully'
                            });
                            //Refresh SLOs
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                            this.dirty = false;
                            this.resetSLOsBulk();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }else {
                    state.flash({
                        type: 'error',
                        message: "Please select SLOs to edit"
                    });
                }
            },
            async saveSLO(slo) {
                try {
                    let res = await state.api.call("updateSLOCap", {
                        sloIDs: slo.rid,
                        cap: slo.cap
                    });
                    if (res.result.success) {
                        state.flash({
                            type: 'success',
                            message: 'SLO cap has been changed successfully'
                        });
                        //Refresh SLOs
                        await this.prepInvoices();
                        await this.prepApprovals();
                        await this.prepSLOs();
                        await this.prepWaitlists();
                        this.dirty = false;
                    } else {
                        throw new Error('Something went wrong. Please refresh and try again.');
                    }
                } catch (error) {
                    console.error(error);
                    state.flash({
                        type: 'error',
                        message: error.message
                    });
                }
            },
            async deleteBulkSLOs() {
                let c = confirm("Are you sure you want to delete these SLOs? This cannot be undone.");
                if (c) {
                    try {
                        let res = await state.api.call("deleteSLO", {
                            sloIDs: this.sloTableState.selectedRows.map(el => el.rid)
                        });
                        if (res.result && res.result[0] && res.result[0].success) {
                            state.flash({
                                type: 'success',
                                message: 'SLOs have been deleted successfully'
                            });
                            //Refresh SLOs
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                            this.resetSLOsBulk();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async deleteSLO(slo) {
                let c = confirm("Are you sure you want to delete this SLO? This cannot be undone.");
                if (c) {
                    try {
                        let res = await state.api.call("deleteSLO", {
                            sloIDs: slo.rid,
                        });
                        if (res.result && res.result[0] && res.result[0].success) {
                            state.flash({
                                type: 'success',
                                message: 'SLO has been deleted successfully'
                            });
                            //Refresh SLOs
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async processSLOLeadSales() {
                let c = confirm("Are you sure you want to process sales for all SLOs in the sytem?");
                if (c) {
                    try {
                        let res = await state.api.call("processSLOLeadSales");
                        // console.log(res);
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'SLO Leads have been processed successfully'
                            });
                            //Refresh Waitlists
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async processLeadSalesByType(type) {
                let c = confirm(`Are you sure you want to process SLO sales for all unsold ${type} leads in the system?`);
                if (c) {
                    try {
                        let res = await state.api.call("processLeadSalesByType", {
                            type: type
                        });
                        // console.log(res);
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: `Unsold ${type} leads have been processed successfully`
                            });
                            //Refresh Waitlists
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async processInvoices() {
                let c = confirm("Are you sure you want to process all failed and pending invoices in the system?");
                if (c) {
                    try {
                        let res = await state.api.call("processInvoices");
                        // console.log(res);
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'Invoices have been processed successfully'
                            });
                            await this.prepInvoices();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async addInvoiceFee(invoice) {
                let total = prompt('Please enter the dollar value of the fee, i.e. 5.99');
                if (total) {
                    if (parseFloat(total.trim())) {
                        let fee = parseFloat(total.trim()).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                        try {
                            let res = await state.api.call("addInvoiceFee", {
                                agentID: invoice.agentid,
                                fee: fee
                            });
                            if (res.result.success) {
                                state.flash({
                                    type: 'success',
                                    message: 'Invoice fee added successfully'
                                });
                                //Refresh Waitlists & Refresh Approvals
                                await this.prepInvoices();
                            } else {
                                throw new Error('Something went wrong. Please refresh and try again.');
                            }
                        } catch (error) {
                            console.error(error);
                            state.flash({
                                type: 'error',
                                message: error.message
                            });
                        }
                    }else {
                        state.flash({
                            type: 'error',
                            message: 'Please try again and ensure you typed the dollar value correctly'
                        });
                    }
                }
            },
            cancelBulkWaitlistsEdit() {
                this.dirty = false;
                this.waitlistBulkEditing = false;
            },
            cancelWaitlistEdit(waitlist) {
                waitlist.payment = waitlist.savedPayment;
                this.dirty = false;
                waitlist.editing = false;
            },
            editWaitlist(waitlist) {
                this.dirty = true;
                waitlist.editing = true;
            },
            editBulkWaitlists() {
                this.dirty = true;
                this.waitlistBulkEditing = true;
            },
            resetWaitlistsBulk() {
                this.waitlistRowsInVewSelected = false;
                this.waitlistAllRowsSelected = false;
                this.waitlistBulkEdit = false;
                this.waitlistBulkEditing = false;
                this.$refs['activeWaitlists'].deselectAll();
                this.waitlistBulkCap = -1;
            },
            async saveWaitlists() {
                if (this.waitlistTableState.selectedRows.length) {
                    try {
                        let res = await state.api.call("updateWaitlistCap", {
                            waitlistIDs: this.waitlistTableState.selectedRows.map(el => el.rid),
                            cap: this.waitlistBulkCap
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'Waitlists’ caps saved successfully'
                            });
                            //Refresh Waitlists
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                            this.dirty = false;
                            this.resetWaitlistsBulk();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }else {
                    state.flash({
                        type: 'error',
                        message: "Please select waitlists to edit"
                    });
                }
            },
            async saveWaitlist(waitlist) {
                try {
                    let res = await state.api.call("updateWaitlistCap", {
                        waitlistIDs: waitlist.rid,
                        cap: waitlist.cap
                    });
                    if (res.result.success) {
                        state.flash({
                            type: 'success',
                            message: 'Waitlist cap has been changed successfully'
                        });
                        //Refresh Waitlists
                        await this.prepInvoices();
                        await this.prepApprovals();
                        await this.prepSLOs();
                        await this.prepWaitlists();
                        this.dirty = false;
                    } else {
                        throw new Error('Something went wrong. Please refresh and try again.');
                    }
                } catch (error) {
                    console.error(error);
                    state.flash({
                        type: 'error',
                        message: error.message
                    });
                }
            },
            async deleteWaitlists() {
                if (this.waitlistTableState.selectedRows.length) {
                    let proceed = confirm("Are you sure you want to delete these waitlists? This cannot be undone.");
                    if (proceed) {
                        try {
                            let res = await state.api.call("deleteWaitlist", {
                                waitlistIDs: this.waitlistTableState.selectedRows.map(el => el.rid),
                                agentID: state.user.rid
                            });
                            if (res.result.success) {
                                state.flash({
                                    type: 'success',
                                    message: 'Waitlists have been deleted successfully'
                                });
                                //Refresh Waitlists
                                await this.prepInvoices();
                                await this.prepApprovals();
                                await this.prepSLOs();
                                await this.prepWaitlists();
                                this.dirty = false;
                                this.resetWaitlistsBulk();
                            } else {
                                throw new Error('Something went wrong. Please refresh and try again.');
                            }
                        } catch (error) {
                            console.error(error);
                            state.flash({
                                type: 'error',
                                message: error.message
                            });
                        }
                    }
                }else {
                    state.flash({
                        type: 'error',
                        message: "Please select waitlists to delete"
                    });
                }
            },
            async deleteWaitlist(waitlist) {
                let c = confirm("Are you sure you want to delete this waitlist? This cannot be undone.");
                if (c) {
                    try {
                        let res = await state.api.call("deleteWaitlist", {
                            waitlistIDs: waitlist.rid,
                            agentID: waitlist.userid
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'Waitlist has been deleted successfully'
                            });
                            //Refresh Waitlists & Refresh Approvals
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async deleteInvoice(invoice) {
                let c = confirm("Are you sure you want to delete this invoice set? This cannot be undone.");
                if (c) {
                    try {
                        let res = await state.api.call("deleteInvoice", {
                            invoiceID: invoice.rid,
                            agentID: invoice.agentid
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'Invoice set has been deleted successfully'
                            });
                            //Refresh Waitlists & Refresh Approvals
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            async processInvoice(invoice) {
                let c = confirm("Are you sure you want to process this invoice set and charge the agent immediately?");
                if (c) {
                    try {
                        let res = await state.api.call("processInvoice", {
                            invoiceID: invoice.rid,
                            agentID: invoice.agentid
                        });
                        if (res.result.success) {
                            state.flash({
                                type: 'success',
                                message: 'Invoice set has been processed successfully'
                            });
                            //Refresh Waitlists & Refresh Approvals
                            await this.prepInvoices();
                            await this.prepApprovals();
                            await this.prepSLOs();
                            await this.prepWaitlists();
                        } else {
                            throw new Error('Something went wrong. Please refresh and try again.');
                        }
                    } catch (error) {
                        console.error(error);
                        state.flash({
                            type: 'error',
                            message: error.message
                        });
                    }
                }
            },
            formatDecimal(num) {
                return round(num, 2);
            },
            formatPercentage(num) {
                return this.formatDecimal(num*100);
            },
            formatMoney(num) {
                return USDollar.format(num);
            },
            async setClosingRatio() {
                if (this.activeAgent) {
                    let ratio = await state.api.call("getStat", {
                        customerID: this.activeAgent,
                        type: 'closing',
                        leadType: this.ratioActiveType
                    });
                    this.closingRatio = ratio.stat;
                    // console.log(this.closingRatio);
                }
            },
            async setAPL() {
                if (this.activeAgent) {
                    let apl = await state.api.call("getStat", {
                        customerID: this.activeAgent,
                        type: 'apl',
                        leadType: this.aplActiveType
                    });
                    this.apl = apl.stat;
                }
            },
            async setViewAsUser() {
                try {
                    let ruser = await state.api.call("returnUserById", { uid: this.viewAsUser });
                    if (ruser) {
                        let sess = await state.api.call("setUserSession", { 
                            adminid: state.user.rid,
                            uid: ruser.rid,
                            email: ruser.emailBusiness,
                            fname: ruser.firstName,
                            lname: ruser.lastName
                        });
                        window.location.href = "/my-account";
                    }
                } catch (error) {
                    console.log(error);
                    state.flash({
                        type: 'error',
                        message: error.errorMessage
                    });
                }
			}
        }
    }
</script>

<template>
    <div class="container" v-if="pageState == 'landing'">
        <div class="title">Invoices</div>
        <button class="btn-white" @click="pageState = 'invoices'" style="margin-right: 10px;">
            <img alt="icon" src="@/assets/invoice.svg">
            Invoices
        </button>
        <button class="btn-white" @click="processInvoices">
            Process Invoices
        </button>

        <div class="title" style="margin-top:30px;">SLO & Waitlist Requests/Approvals</div>
        <button class="btn-white" @click="pageState = 'approvals'">
            <img alt="icon" src="@/assets/request.svg">
            Approvals/Requests
        </button>

        <div class="title" style="margin-top:30px;">SLO Management</div>
        <button class="btn-white" @click="pageState = 'slos'" style="margin-right: 10px;">
            <img alt="icon" src="@/assets/slos.svg">
            Active SLOs
        </button>
        <button class="btn-white" @click="processSLOLeadSales">
            Process SLO Lead Sales
        </button>

        <div class="title" style="margin-top:30px;">Waitlist Management</div>
        <button class="btn-white" @click="pageState = 'waitlists'">
            <img alt="icon" src="@/assets/waitlists.svg">
            Active Waitlists
        </button>

        <div class="title" style="margin-top:30px;">Lead Management</div>
        <button class="btn-white" @click="processLeadSalesByType('VF')" style="margin-right: 10px;">
            Process VF Lead Sales
        </button>
        <button class="btn-white" @click="processLeadSalesByType('V1')">
            Process V1 Lead Sales
        </button>

        <div class="title" style="margin-top:30px;">Performance</div>
        <button class="btn-white" @click="pageState = 'performance'" style="margin-right: 10px;">
            <img alt="icon" src="@/assets/percent.svg">
            Agent Performance
        </button>

        <div class="title" style="margin-top:30px;">Misc</div>
        <button class="btn-white" @click="pageState = 'loginAsUser'" style="margin-right: 10px;">
            Login as User
        </button>
    </div>

    <div class="container" v-else-if="pageState == 'invoices'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            Invoices
        </div>
        <VTable :data="invoices" ref="activeInvoices" :page-size="20" v-model:currentPage="invoiceCurrentPage" @totalPagesChanged="invoiceTotalPages = $event" @stateChanged="invoiceTableState = $event" :selectOnClick="false" :filters="invoiceFilters" sortIconPosition="before">
            <template #head>
                <tr>
                    <VTh sortKey="modifiedDate" defaultSort="desc" class="_date">Paid/Failed Date</VTh>
                    <VTh sortKey="agent" class="_agent">
                        Agent
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="invoiceFilters.agent.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="status" class="_status">
                        Status
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="invoiceFilters.status.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="invoices" class="_invoices">
                        Invoice Numbers
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="invoiceFilters.invoices.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <th class="_leads">
                        # of Leads
                    </th>
                    <VTh sortKey="sloswaitlists" class="_sloswaitlists">
                        Related SLOs/Waitlists
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="invoiceFilters.sloswaitlists.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh :sortKey="invoiceTotal" class="_total">
                        Total
                    </VTh>
                    <th class="_actions" style="width:325px;">
                        Actions
                    </th>
                </tr>
            </template>
            <template #body="{rows}" v-if="invoices.length">
                <VTr v-for="invoice in rows" :key="invoice.rid" :row="invoice">
                    <td class="_date">{{ state.unixToDate(invoice.modifiedDate) }}</td>
                    <td class="_agent">{{ invoice.agent }}</td>
                    <td class="_status -approved" v-if="invoice.status === 'Paid'"><span>Paid</span></td>
                    <td class="_status -denied" v-else-if="invoice.status === 'Failed'"><span>Not Paid</span></td>
                    <td class="_status -pending" v-else><span>Pending</span></td>
                    <td class="_invoices">{{ invoice.invoices }}</td>
                    <td class="_leads">{{ invoice.fee ? 'n/a' : invoice.totalLeads }}</td>
                    <td class="_sloswaitlists">{{ invoice.sloswaitlists || "n/a" }}{{ invoice.fee ? ' - One Time Fee' : '' }}</td>
                    <td class="_total">${{ invoice.total }} <span class="_note" v-if="invoice.costShare" v-tippy="'This invoice includes amounts as part of a cost share.'">Cost Share</span></td>
                    <td class="_actions" style="width:325px;">
                        <button class="-save" @click="addInvoiceFee(invoice)" v-if="!invoice.fee">Add Fee</button>
                        <button class="-default" @click="processInvoice(invoice)">Process</button>
                        <button class="-delete" @click="deleteInvoice(invoice)">Delete</button>
                    </td>
                </VTr>
            </template>
            <template #body="" v-else>
                <VTr>
                    <td style="width:100%;" class="_empty">No invoices found</td>
                </VTr>
            </template>
        </VTable>
        <VTPagination
			v-model:currentPage="invoiceCurrentPage"
			:total-pages="invoiceTotalPages"
			:boundary-links="true"
		/>
    </div>

    <div class="container" v-else-if="pageState == 'approvals'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            SLO & Waitlist Requests/Approvals
        </div>
        <VTable :data="approvals" ref="activeApprovals" :page-size="100" v-model:currentPage="approvalCurrentPage" @totalPagesChanged="approvalTotalPages = $event" @stateChanged="approvalTableState = $event" :selectOnClick="false" :filters="approvalFilters" sortIconPosition="before" selectionMode="multiple">
            <template #head>
                <tr>
                    <th class="checkbox-wrapper">
						<input type="checkbox" :checked="approvalRowsInVewSelected" @change="toggleRowsInView('activeApprovals')" />
					</th>
                    <VTh :sortKey="approvalDecision" defaultSort="desc" class="_status">
                        Status
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="approvalFilters.status.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh :sortKey="dateSort" class="_date">
                        Date
                    </VTh>
                    <VTh sortKey="user" class="_agent">
                        Agent
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="approvalFilters.user.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="lead" class="_lead">
                        Lead Type
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="approvalFilters.type.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="county" class="_county">
                        County
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="approvalFilters.county.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="state" class="_state">
                        State
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="approvalFilters.state.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <th class="_comments">
                        Comments
                    </th>
                    <th class="_actions">
                        Actions
                    </th>
                </tr>
            </template>
            <template #body="{rows}" v-if="approvals.length">
                <VTr v-for="approval in rows" :key="approval.rid" :row="approval" v-slot="{ isSelected, toggle}">
                    <td class="checkbox-wrapper">
						<input type="checkbox" :checked="isSelected" @change="toggle" @click="tableRowClicked('activeApprovals')" v-if="approval.decision === ''"/>
					</td>
                    <td class="_status -approved" v-if="approval.decision"><span>Approved</span></td>
                    <td class="_status -pending" v-else-if="approval.decision === ''"><span>Pending</span></td>
                    <td class="_status -denied" v-else><span>Denied</span></td>
                    <td class="_date">{{ approval.date.getMonth() + 1 }}/{{ approval.date.getDate() }}/{{
                        approval.date.getFullYear() - 2000 }} @ {{ approval.date.getHours() > 12 ?
                        approval.date.getHours() - 12 : approval.date.getHours().toString().padStart(2, '0') }}:{{
                        approval.date.getMinutes().toString().padStart(2, '0') }}:{{
                        approval.date.getSeconds().toString().padStart(2, '0') }} {{ approval.date.getHours() > 12 ?
                        'PM' : 'AM' }}</td>
                    <td class="_agent">{{ approval.user }}</td>
                    <td class="_lead">{{ approval.lead }}</td>
                    <td class="_county">{{ approval.county}}</td>
                    <td class="_state">{{ approval.state }}</td>
                    <td class="_comments"><textarea :value="deSanitizeInput(approval.comments)" placeholder="No comments"
                            @blur="saveComments(approval)" @input="editingComments($event,approval)"></textarea></td>
                    <td class="_actions">
                        <button class="-approve" @click="setApprovalStatus(approval,true)"
                            v-if="approval.decision === ''">Approve</button>
                        <button class="-delete" @click="setApprovalStatus(approval,false)"
                            v-if="approval.decision === ''">Reject</button>
                    </td>
                </VTr>
            </template>
            <template #body="" v-else>
                <VTr>
                    <td style="width:100%;" class="_empty">No requests found</td>
                </VTr>
            </template>
        </VTable>
        <VTPagination
			v-model:currentPage="approvalCurrentPage"
			:total-pages="approvalTotalPages"
			:boundary-links="true"
		/>
        <div id="approvalsBulkEdit" class="_bulkEdit -center" :class="{ '-active': approvalBulkEdit}">
            <button class="-approve" @click="bulkSetApprovalStatus(true)">Approve {{ approvalTableState.selectedRows.length }} Requests</button>
            <button class="-delete" @click="bulkSetApprovalStatus(false)">Reject {{ approvalTableState.selectedRows.length }} Requests</button>
        </div>
    </div>

    <div class="container" v-else-if="pageState == 'slos'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            Active SLOs
        </div>
        <VTable :data="slos" ref="activeSLOs" :page-size="100" v-model:currentPage="sloCurrentPage" @totalPagesChanged="sloTotalPages = $event" @stateChanged="sloTableState = $event" :selectOnClick="false" :filters="sloFilters" sortIconPosition="before" selectionMode="multiple">
            <template #head>
                <tr>
                    <th class="checkbox-wrapper">
						<input type="checkbox" :checked="sloRowsInVewSelected" @change="toggleRowsInView('activeSLOs')" />
					</th>
                    <VTh sortKey="user" defaultSort="asc" class="_agent">
                        Agent
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="sloFilters.user.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="leadtype" class="_lead">
                        Lead Type 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="sloFilters.type.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="county" class="_county">
                        County 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="sloFilters.county.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="state" class="_state">
                        State 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="sloFilters.state.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <th class="_cap">Weekly Purchase Cap</th>
                    <th class="_actions">Actions</th>
                </tr>
            </template>
            <template #body="{rows}" v-if="slos.length">
                <VTr v-for="slo in rows" :key="slo.rid" :row="slo" v-slot="{ isSelected, toggle}">
                    <td class="checkbox-wrapper">
						<input type="checkbox" :checked="isSelected" @change="toggle" @click="tableRowClicked('activeSLOs')"/>
					</td>
                    <td class="_agent">{{ slo.user }}</td>
                    <td class="_lead">{{ slo.leadtype }}</td>
                    <td class="_county">{{ slo.county}}</td>
                    <td class="_state">{{ slo.state }}</td>

                    <td class="_cap" v-if="slo.editing">
                        <input type="number" min="-1" v-model="slo.cap" />
                        <label>-1 = no cap; 1+ is a weekly cap setting; 0 effectively means no sales</label>
                    </td>
                    <td class="_cap" v-else>{{ slo.cap &lt; 0 ? 'No Cap':slo.cap }}</td>

                    <td class="_actions">
                        <button class="-edit" @click="editSLO(slo)" v-if="!slo.editing">Edit</button>
                        <button class="-save" @click="saveSLO(slo)" v-if="slo.editing">Save</button>
                        <button class="-delete" @click="deleteSLO(slo)" v-if="!slo.editing">Delete</button>
                    </td>
                </VTr>
            </template>
            <template #body="" v-else>
                <VTr>
                    <td style="width:100%;" class="_empty">No SLOs found</td>
                </VTr>
            </template>
        </VTable>
        <VTPagination
			v-model:currentPage="sloCurrentPage"
			:total-pages="sloTotalPages"
			:boundary-links="true"
		/>
        <div id="slosBulkEdit" class="_bulkEdit" :class="{ '-active': sloBulkEdit}">
            <span v-if="!sloBulkEditing">Edit <strong>{{ sloTableState.selectedRows.length }}</strong> SLOs</span>
            <div class="input-group" v-if="sloBulkEditing">
                <label>Cap</label>
                <input type="number" min="-1" v-model="sloBulkCap" />
            </div>
            <button class="-cancel" @click="cancelBulkSLOsEdit" v-if="sloBulkEditing">Cancel</button>
            <button class="-edit" @click="editBulkSLOs" v-if="!sloBulkEditing">Edit</button>
            <button class="-delete" @click="deleteBulkSLOs" v-if="!sloBulkEditing">Delete</button>
            <button class="-save" @click="saveSLOs" v-if="sloBulkEditing">Save</button>
        </div>
    </div>

    <div class="container" v-else-if="pageState == 'waitlists'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            Active Waitlists
        </div>
        <VTable :data="waitlists" ref="activeWaitlists" :page-size="100" v-model:currentPage="waitlistCurrentPage" @totalPagesChanged="waitlistTotalPages = $event" @stateChanged="waitlistTableState = $event" :selectOnClick="false" :filters="waitlistFilters" sortIconPosition="before" selectionMode="multiple">
            <template #head>
                <tr>
                    <th class="checkbox-wrapper">
						<input type="checkbox" :checked="waitlistRowsInVewSelected" @change="toggleRowsInView('activeWaitlists')" />
					</th>
                    <VTh :sortKey="dateSort" defaultSort="asc" class="_date">
                        Date
                    </VTh>
                    <VTh sortKey="user" class="_agent">
                        Agent
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="waitlistFilters.user.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="leadtype" class="_lead">
                        Lead Type 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="waitlistFilters.type.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="county" class="_county">
                        County 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="waitlistFilters.county.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <VTh sortKey="state" class="_state">
                        State 
                        <button class="_filterToggle" title="Filter" @click="filterToggle($event)">Filter</button>
                        <input class="_filter" placeholder="…type to filter" v-model="waitlistFilters.state.value" @click="(event) => event.stopPropagation()" />
                    </VTh>
                    <th class="_cap">Weekly Purchase Cap</th>
                    <th class="_actions">Actions</th>
                </tr>
            </template>
            <template #body="{rows}" v-if="waitlists.length">
                <VTr v-for="waitlist in rows" :key="waitlist.rid" :row="waitlist" v-slot="{ isSelected, toggle}">
                    <td class="checkbox-wrapper">
						<input type="checkbox" :checked="isSelected" @change="toggle" @click="tableRowClicked('activeWaitlists')"/>
					</td>
                    <td class="_date">{{ waitlist.date.getMonth() + 1 }}/{{ waitlist.date.getDate() }}/{{ waitlist.date.getFullYear() - 2000 }} @ {{ waitlist.date.getHours() > 12 ? waitlist.date.getHours() - 12 : waitlist.date.getHours().toString().padStart(2, '0') }}:{{ waitlist.date.getMinutes().toString().padStart(2, '0') }}:{{ waitlist.date.getSeconds().toString().padStart(2, '0') }} {{  waitlist.date.getHours() > 12 ? 'PM' : 'AM' }}</td>
                    <td class="_agent">{{ waitlist.user }}</td>
                    <td class="_lead">{{ waitlist.leadtype }}</td>
                    <td class="_county">{{ waitlist.county}}</td>
                    <td class="_state">{{ waitlist.state }}</td>

                    <td class="_cap" v-if="waitlist.editing">
                        <input type="number" min="-1" v-model="waitlist.cap" />
                        <label>-1 = no cap; 1+ is a weekly cap setting; 0 effectively means no sales</label>
                    </td>
                    <td class="_cap" v-else>{{ waitlist.cap &lt; 0 ? 'No Cap' : waitlist.cap }}</td>

                    <td class="_actions">
                        <button class="-edit" @click="editWaitlist(waitlist)" v-if="!waitlist.editing">Edit</button>
                        <button class="-save" @click="saveWaitlist(waitlist)" v-if="waitlist.editing">Save</button>
                        <button class="-delete" @click="deleteWaitlist(waitlist)"
                            v-if="!waitlist.editing">Delete</button>
                    </td>
                </VTr>
            </template>
            <template #body="" v-else>
                <VTr>
                    <td style="width:100%;" class="_empty">No waitlists found</td>
                </VTr>
            </template>
        </VTable>
        <VTPagination
			v-model:currentPage="waitlistCurrentPage"
			:total-pages="waitlistTotalPages"
			:boundary-links="true"
		/>
        <div id="waitlistsBulkEdit" class="_bulkEdit" :class="{ '-active': waitlistBulkEdit}">
            <span v-if="!waitlistBulkEditing">Edit <strong>{{ waitlistTableState.selectedRows.length }}</strong> Waitlists</span>
            <div class="input-group" v-if="waitlistBulkEditing">
                <label>Cap</label>
                <input type="number" min="-1" v-model="waitlistBulkCap" />
            </div>
            <button class="-cancel" @click="cancelBulkWaitlistsEdit" v-if="waitlistBulkEditing">Cancel</button>
            <button class="-edit" @click="editBulkWaitlists" v-if="!waitlistBulkEditing">Edit</button>
            <button class="-save" @click="saveWaitlists" v-if="waitlistBulkEditing">Save</button>
            <button class="-delete" @click="deleteWaitlists" v-if="!waitlistBulkEditing">Delete</button>
        </div>
    </div>

    <div class="container" v-else-if="pageState == 'performance'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            Agent Performance
            <select v-model="activeAgent" id="agentSelect">
              <template v-for="agent in allAgents" :key="agent.rid">
                <option :value="agent.rid">{{ agent.username }}</option>
              </template>
            </select>
        </div>
        <div class="_stats">
          <div>
            <h3>Closing Ratio</h3>
            <select v-model="ratioActiveType">
              <template v-for="lead in leadTypes" :key="lead">
                <option v-if="lead == ratioActiveType" selected :value="lead">{{ lead }} Leads</option>
								<option v-else :value="lead">{{ lead }} Leads</option>
              </template>
            </select>
            <h2>{{ formatPercentage(closingRatio.ratio) }}% <span>{{ ratioActiveType }} Leads ({{ closingRatio.sold }} / {{ closingRatio.unsold }})</span></h2>
            <p><em>This is a ratio of your <strong>SOLD</strong> leads vs. your <strong>UNSOLD</strong> leads.</em></p>
            <div class="_benchmarks">
              <h4>Victory Benchmarks:</h4>
              <ul>
                <li><u>30-35%</u> on <strong>VF Leads</strong></li>
                <li><u>5-10%</u> on <strong>all other lead types</strong></li>
              </ul>
            </div>
          </div>
          <div>
            <h3>APL</h3>

            <select v-model="aplActiveType">
              <template v-for="lead in leadTypes" :key="lead">
                <option v-if="lead == aplActiveType" selected :value="lead">{{ lead }} Leads</option>
								<option v-else :value="lead">{{ lead }} Leads</option>
              </template>
            </select>
            <h2>{{ formatMoney(apl.average) }} <span>{{ aplActiveType }} Leads ({{ formatMoney(apl.total) }} / {{ apl.leadCount }})</span></h2>

            <p><em>This is your <strong>Total Annual Premiums</strong> sold per <strong># of Purchased Leads</strong></em></p>
            <div class="_benchmarks">
              <h4>Victory Benchmarks:</h4>
              <ul>
                <li><u>$250-$350</u> on <strong>VF Leads</strong></li>
                <li><u>$50-$100</u> on <strong>all other lead types</strong></li>
              </ul>
            </div>
          </div>
        </div>
    </div>

    <div class="container" v-else-if="pageState == 'loginAsUser'">
        <div class="title">
            <button class="btn-inline" @click="back">
                <img alt="exit icon" src="@/assets/arrow-left.svg" style="margin-right: 8px;">
            </button>
            Login as User
            <select v-model="viewAsUser" id="userSelect" style="display: inline-block; margin-top: 0.5rem; margin-left: 2rem;">
              <template v-for="agent in allAgents" :key="agent.rid">
                <option :value="agent.rid">{{ agent.username }}</option>
              </template>
            </select>
            <button @click="setViewAsUser" class="btn-blue-light" style="display:inline-block;margin-left:2rem;">View</button>
        </div>
    </div>

</template>

<style scoped>
.container {
    display: flex;
    padding: 24px 24px 0;
    flex-wrap: wrap;
    flex-grow: initial;
}

.title {
    color: var(--btn-blue-dark);
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    width: 100%;
}

.btn-white, .btn-blue-dark {
    margin-right: auto;
    margin-top: 12px;

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-inline {
    padding: 0;
    border: 0;
    outline: 0;
    appearance: none;
}

/* Table Styles */
table {
	width: 100%;
    margin: 30px auto;
	border-collapse: collapse;

    label {
        font-size: 13px;
        font-style: italic;
        display: block;
        margin-top: 5px;
    }

    input:not(._filter) {
        padding: 5px;
        font-size: 15px;
    }
}

thead {
	width: 100%;
}

thead tr {
	font-size: 12px;
	line-height: 16px;
	color: #2A6BB8;

	background: white;
}

tbody tr {
	font-size: 16px;
	line-height: 24px;

	height: auto;
}

tbody tr:hover {
	background: white;
}

tr {
	width: 100%;
	border-bottom: 1px solid #B0CCEE;

	display: flex;
}

th,
td {
	width: 16.6667%;
	text-align: left;
	display: flex;
	align-items: center;
    margin: 0;
    padding: 10px;

    &._status {
        width: 100px;
    }
    &._agent {
        width: 400px;
    }
    &._date {
        width: 190px;
    }
    &._comments {
        width: calc(100% - 1275px);
    }
    &._lead {
        width: 100px;
    }
    &._county {
        width: 115px;
    }
    &._state {
        width: 115px;
    }
    &._leads {
        width: 115px;
    }
    &._actions {
        width: 175px;
    }
    &._cap {
        flex-wrap: wrap;
    }
}

th {
	height: 52px;
}

textarea {
    width: 100%;
    resize: vertical;
    padding: 10px;
    height: calc(100% - 12px);
}

/* END Table Style */

@media (min-width:1280px) {
    #container {
        padding-left: 240px;
        padding-right: 240px;
    }
}

button img {
    margin-right: 8px;
    width: 16px;
}

table button {
    display: block;
    padding: 8px 12px;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 10px;
    text-transform: uppercase;

    &:not(:last-of-type):not(:first-of-type) {
        margin-left: 8px;
    }
}
button.-approve,
button.-save {
    background-color: var(--color-green);
    color: #fff
}
button.-delete {
    background-color: var(--color-red);
    color: #fff
}
button.-default,
button.-edit {
    background-color: var(--btn-blue-light);
    color: #fff
}

._status {
    & span {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        padding: 6px 10px;
        border-radius: 3px;
    }

    &.-approved span {
        background-color: var(--color-green);
    }
    &.-pending span {
        background-color: var(--color-orange);
    }
    &.-denied span {
        background-color: var(--color-red);
    }
}

.input-group {
    width: 45%;
    margin-bottom: 9px;
    height: auto;
}
</style>
<style>
th input._filter {
    display: none;
    width: calc(100% - 18px);
    margin-top: 5px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 5px 8px;
}
table button._filterToggle {
    display: block !important;
    width: 16px;
    padding: 0 !important;
    margin: 0;
    aspect-ratio: 1/1;
    appearance: none;
    cursor: pointer;
    border: 0 !important;
    background-repeat: no-repeat;
    background-size: auto 70%;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.68' height='14'%3E%3Cpath d='M5.63 14c-.27 0-.53-.11-.72-.3-.19-.19-.3-.45-.29-.72v-4.9c0-.16-.05-.32-.15-.45L.19 1.61C-.03 1.3-.06.89.11.55.28.21.63 0 1.01 0h11.65c.38 0 .73.21.9.55.18.34.15.75-.08 1.06L9.21 7.62c-.1.14-.16.3-.16.46v3.98c0 .45-.28.83-.69.96l-2.37.9c-.11.05-.25.07-.38.07Zm.13-7.3c.29.4.45.88.45 1.39v4.06l1.26-.48V8.09c0-.51.16-.99.46-1.39l3.62-5.09H2.14l3.62 5.1Z' style='fill:%232a6bb8;stroke-width:0'/%3E%3C/svg%3E");
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0 !important;
}
.v-th {
    cursor: pointer;
}
.v-th.-filter {
    ._filter {
        display: block;
    }
    ._filterToggle {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.68' height='14'%3E%3Cpath d='M5.63 14c-.27 0-.53-.11-.72-.3-.19-.19-.3-.45-.29-.72v-4.9c0-.16-.05-.32-.15-.45L.19 1.61C-.03 1.3-.06.89.11.55.28.21.63 0 1.01 0h11.65c.38 0 .73.21.9.55.18.34.15.75-.08 1.06L9.21 7.62c-.1.14-.16.3-.16.46v3.98c0 .45-.28.83-.69.96l-2.37.9c-.11.05-.25.07-.38.07Z' style='fill:%232a6bb8;stroke-width:0'/%3E%3C/svg%3E");
    }
}
.v-th div {
    align-items: flex-start;
    width: 100%;
    span {
        display: block;
        position: relative;
        width: calc(100% - 16px);
        padding-right: 24px;
    }
}
.vt-pagination {
    width: 100%;

    & .pagination {
        list-style-type: none;
        padding: 0;
        margin: 10px auto 70px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        & li {
            margin: 3px;
            border: 1px solid #ccc;
            border-radius: 3px;
            background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.2) 100%);
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,1) 100%);
            }

            &.disabled {
                opacity: 0.5;
            }
            &.active {
                background: linear-gradient(to top, #fff 0%, rgba(255,255,255,0.2) 100%);
                & a {
                    font-weight: 600;
                    color: var(--btn-blue-dark);
                }
            }
        }

        & a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #4d4d4d;
            text-decoration: none;
            padding: 5px 6px;
        }
        & svg {
            transform: scale(0.75);
        }
    }
}
#agentSelect,
#userSelect {
    max-width: 100%;
    width: 540px;
}
._stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 20px;
    
    & * {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
    }

    & > div {
      width: calc(50%);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 30px 30px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:first-of-type {
        border-right: 1px solid rgba(0,0,0,0.1);

          @media screen and (max-width: 768px) {
            border-right: 0;
            border-bottom: 1px solid rgba(0,0,0,0.1);
          }
      }
    }
    h3 {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    h2 {
        display: block;
        width: 50%;
        text-align: center;
        font-size: 46px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 20px;
        color: var(--btn-blue-light);
        padding: 20px;

        &:first-of-type {
        width: 100%;
        font-size: 60px;
        }

        @media screen and (max-width: 1080px) {
        width: 100%;
        }

        & span {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--btn-blue-dark);
        margin-top: 10px;
        display: block !important;
        font-style: italic;
        line-height: 1.3;
        }
    }
    h4 {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-top: 0;
        text-transform: uppercase;
        color: rgba(255,255,255,0.9);
    }
    p {
        font-size: 16px;
        text-align: center;
        width: 100%;
        line-height: 1.3;
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
    }
    li {
        padding: 0;
        margin: 5px 0;
        line-height: 1.3;
    }
  }
  ._benchmarks {
    display: block;
    margin: 40px auto 0;
    max-width: 600px;
    padding: 25px 30px;
    border-radius: 10px;
    background-color: var(--btn-blue-light);

    p, ul {
      color: #fff;
    }
  }
</style>